import { Spinner, SpinnerProps } from '@chakra-ui/react';
import { useTranslation } from '../lib/hooks.context';

const Loader = (props: SpinnerProps) => {
  const t = useTranslation();
  return (
    <Spinner
      aria-hidden="true"
      size="md"
      m="2rem auto"
      title={t('loading')}
      {...props}
    />
  );
};
export default Loader;
